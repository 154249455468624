import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import App from './App';
import {
  ThemeProvider,
  createMuiTheme,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';
import * as Langs from './../compiled-lang';

import { MuiTheme } from '@aim/components';
import { appState } from '@aim/common';

const LoaderRootComponent = ({ appName }) => {
  const [locale, setLocale] = useState('en');

  const theme = createMuiTheme(MuiTheme);

  useEffect(() => {
    const localeSubscription = appState.locale.subscribe((locale) => {
      setLocale(locale);
    });

    return () => {
      localeSubscription.unsubscribe();
    };
  }, []);

  const generateClassName = createGenerateClassName({
    seed: 'aim-loader',
  });
  return (
    <IntlProvider locale={locale} messages={Langs[locale]}>
      <ThemeProvider theme={theme}>
        <StylesProvider generateClassName={generateClassName}>
          <App appName={appName} />
        </StylesProvider>
      </ThemeProvider>{' '}
    </IntlProvider>
  );
};

export default LoaderRootComponent;
